import axios from 'axios'
import store from '../../../store'

axios.interceptors.request.use(async function (config) {
  if (store.state.token && config.url !== 'auth/token') {
    const token = store.state.token
    const accessToken = token.access_token
    const token_type = token.token_type
    const authorization = `${token_type} ${accessToken}`
    config.headers.Authorization = authorization
    return config
  } else {
    config.headers.Authorization = null
    return config
  }
})
