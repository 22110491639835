import axios from 'axios'

export default {
  fetchUser: async ({ commit }) => {
    commit('SET_APP_LOADING', false, { root: true })

    const userResponse = await axios.get('usuarios/')
    const user = userResponse.data
    commit('SET_USER', user)
    commit('SET_APP_LOADING', false, { root: true })
  },
  putUser: async ({ commit }, payload) => {
    commit('SET_APP_LOADING', false, { root: true })

    try {
      const userResponse = await axios.put('usuarios/', payload)
      const user = userResponse.data
      commit('SET_USER', user)
    } finally {
      commit('SET_APP_LOADING', false, { root: true })
    }
  },
  fetchEmpresa: async ({ commit }) => {
    commit('SET_APP_LOADING', true, { root: true })
    const empresaResponse = await axios.get('empresas/')
    const empresa = empresaResponse.data
    commit('SET_EMPRESA', empresa)
    commit('SET_APP_LOADING', false, { root: true })
  },
  fetchNotificaciones: async ({ commit }) => {
    const notificacionesResponse = await axios.get('iot/notificacionesweb/')
    const notificaciones = notificacionesResponse.data
    commit('SET_NOTIFICACIONES', notificaciones)
  },
  putEmpresa: async ({ commit }, payload) => {
    commit('SET_APP_LOADING', true, { root: true })
    try {
      const empresaResponse = await axios.put('empresas/', payload)
      const user = empresaResponse.data
      commit('SET_EMPRESA', user)
    } finally {
      commit('SET_APP_LOADING', false, { root: true })
    }
  },

  // fetchLogo: async ({ commit }) => {
  //   const logoResponse = await axios.get('empresas/logo/')
  //   const logo = logoResponse.data.logo
  //   commit('SET_LOGO', logo)
  // },
  putLogo: async ({ commit }, payload) => {
    commit('SET_APP_LOADING', false, { root: true })
    try {
      const logoResponse = await axios.put('empresas/logo/', payload)
      const logo = logoResponse.data
      commit('SET_EMPRESA', logo)
    } finally {
      commit('SET_APP_LOADING', false, { root: true })
    }
  },
  fetchMediosNotificacion: async ({ commit }) => {
    const mediosNotificacionResponse = await axios.get('empresas/medio_notificacion/')
    const mediosNotificacion = mediosNotificacionResponse.data
    const mediosNotificacionSMS = mediosNotificacion.filter(({ id, medio, numeroDireccion }) => medio === 'sms')
    commit('SET_MEDIO_NOTIFICACION_SMS', mediosNotificacionSMS)

    const mediosNotificacionWA = mediosNotificacion.filter(({ id, medio, numeroDireccion }) => medio === 'wa')
    commit('SET_MEDIO_NOTIFICACION_WA', mediosNotificacionWA)

    const mediosNotificacionCorreo = mediosNotificacion.filter(({ id, medio, numeroDireccion }) => medio === 'correo')
    commit('SET_MEDIO_NOTIFICACION_CORREO', mediosNotificacionCorreo)
  },
  putMediosNotificacion: async ({ commit }, payload) => {
    commit('SET_APP_LOADING', false, { root: true })

    try {
      const mediosNotificacionResponse = await axios.post('empresas/medio_notificacion/', payload)
      const medio = mediosNotificacionResponse.data.medio
      const mediosNotificacion = mediosNotificacionResponse.data.medios_notificacion
      switch (medio) {
        case 'sms':
          commit('SET_MEDIO_NOTIFICACION_SMS', mediosNotificacion)
          break
        case 'wa':
          commit('SET_MEDIO_NOTIFICACION_WA', mediosNotificacion)
          break
        case 'correo':
          commit('SET_MEDIO_NOTIFICACION_CORREO', mediosNotificacion)
          break
      }
    } finally {
      commit('SET_APP_LOADING', false, { root: true })
    }
  },
}
