<template>
  <v-card
    class="v-card--material pa-3"
    :class="classes"
    v-bind="$attrs"
  >
    <div class="d-flex grow flex-wrap">
      <!--    Si  es un card actualizable -->
      <template v-if="avatar">
        <v-tooltip
          bottom
          v-if="avatarUpload"
        >
          <input
            @change="doUploadAvatar"
            accept="image/*"
            ref="logoInput"
            type="file"
            v-show="false"
          >
          <template v-slot:activator="{ on }">
            <v-avatar
              @click="openLogoInput"
              class="mx-auto v-card--material__avatar elevation-6 pointer"
              color="grey"
              size="128"
              v-if="avatar"
              v-on="on"
            >
              <v-img :src="avatar"/>
            </v-avatar>
          </template>
          <span>Click para actualizar.</span>
        </v-tooltip>
        <!--    Si no es un card actualizable, mostrar normal    -->
        <template
          v-else
        >
          <v-avatar
            class="mx-auto v-card--material__avatar elevation-6 pointer"
            color="grey"
            size="128"
            v-if="avatar"
          >
            <v-img :src="avatar"/>
          </v-avatar>
        </template>
      </template>

      <v-sheet
        :class="{
          ['pa-'+ paddingHeading]: !$slots.image
        }"
        :color="color"
        :max-height="icon ? 90 : undefined"
        max-width="100%"
        :width="icon ? 'auto' : widthHeadingResponsive"
        class="text-start v-card--material__heading  ml-a"
        dark
        elevation="6"
        v-else
      >
        <slot
          name="heading"
          v-if="$slots.heading"
        />

        <slot
          name="image"
          v-else-if="$slots.image"
        />

        <div
          class="display-1 font-weight-light"
          v-else-if="title && !icon"
          v-text="title"
        />
        <v-icon
          size="32"
          v-else-if="icon"
          v-text="icon"
        />

        <div
          class="headline font-weight-thin"
          v-if="text"
          v-text="text"
        />
      </v-sheet>

      <div
        class="ml-6"
        v-if="$slots['after-heading']"
      >
        <slot name="after-heading"/>
      </div>

      <div
        class="ml-4"
        v-else-if="icon && title"
      >
        <div

          class="card-title font-weight-light"
          v-text="title"
        />
      </div>
    </div>

    <slot/>

    <template v-if="$slots.actions">
      <v-divider class="mt-2"/>

      <v-card-actions class="pb-0">
        <slot name="actions"/>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'MaterialCard',

    props: {
      avatar: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'success',
      },
      icon: {
        type: String,
        default: undefined,
      },
      image: {
        type: Boolean,
        default: false,
      },
      avatarUpload: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      paddingHeading: {
        type: Number,
        default: 7,
      },
      widthHeading: {
        type: String,
        default: '100%',
      },
    },
    methods: {

      ...mapActions('perfil', ['putLogo']),
      openLogoInput () {
        this.$refs.logoInput.click()
      },
      doUploadAvatar () {
        const logo = this.$refs.logoInput.files[0]
        const payload = new FormData()
        payload.append('logo', logo)
        this.putLogo(payload).then(() => {
          this.$toast.success('Se ha actualizado correctamente.')
        })
      },
    },
    computed: {
      classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
        }
      },
      hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon)
      },
      hasAltHeading () {
        return Boolean(this.$slots.heading || (this.title && this.icon))
      },
      widthHeadingResponsive () {
        switch (this.$vuetify.breakpoint.name) {
          case 'lg':
            return this.widthHeading
          case 'xl':
            return this.widthHeading
          default:
            return 'auto'
        }
      },
    },
  }
</script>

<style lang="sass">
  .pointer
    cursor: pointer

    .v-card--material
      &__avatar
        position: relative
        top: -64px
        margin-bottom: -32px

      &__heading
        position: relative
        top: -40px
        transition: .3s ease
        z-index: 1
</style>
