import { set } from '@/utils/vuex'

export default {
  SET_EMPRESA: set('empresa'),
  SET_USER: set('user'),
  SET_LOGO: set('logo'),
  SET_NOTIFICACIONES: set('notificaciones'),
  SET_MEDIO_NOTIFICACION_WA: set('medios_notificacion_wa'),
  SET_MEDIO_NOTIFICACION_SMS: set('medios_notificacion_sms'),
  SET_MEDIO_NOTIFICACION_CORREO: set('medios_notificacion_correo'),
}
