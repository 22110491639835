// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'

const client_id = process.env.VUE_APP_CLIENT_ID
const client_secret = process.env.VUE_APP_CLIENT_SECRET

export default {
  login: async ({ commit }, payload) => {
    try {
      const grant_type = 'password'
      const form = `client_id=${client_id}&client_secret=${client_secret}&grant_type=${grant_type}&username=${payload.username}&password=${payload.password}`
      const tokenResponse = await axios.post('oauth/token', form, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      const token = tokenResponse.data
      commit('SET_TOKEN', token)
      localStorage.setItem('token', JSON.stringify(token))
    } catch (error) {
      localStorage.clear('token')
      if (error.response.status === 400 && error.response.data.error_description === 'Invalid credentials given.') {
        throw new Error('Usuario o contraseña incorrectas.')
      }
      throw new Error('No se puede iniciar sesión, intente nuevamente en unos minutos.')
    }
  },
  login2: async ({ commit }, payload) => {
    try {
      payload.client_id = client_id
      payload.client_secret = client_secret
      payload.grant_type = 'password'
      const tokenResponse = await axios.post('oauth/token', payload)
      const token = tokenResponse.data
      token.expire_time = Date.now() + token.expires_in * 1000
      // const refreshToken = tokenResponse.data.refresh_token
      commit('SET_TOKEN', token)
      localStorage.setItem('token', JSON.stringify(token))
    } catch (error) {
      localStorage.clear('token')
      if (error.response.status === 400 && error.response.data.error_description === 'Invalid credentials given.') {
        throw new Error('Usuario o contraseña incorrectas.')
      }
      throw new Error('No se puede iniciar sesión, intente nuevamente en unos minutos.')
    }
  },
  refreshToken: async ({ commit }, refresh_token) => {
    try {
      const payload = {
        client_id: client_id,
        client_secret: client_secret,
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
      }
      const tokenResponse = await axios.post('auth/token', payload)
      const token = tokenResponse.data
      token.expire_time = Date.now() + token.expires_in * 1000
      commit('SET_TOKEN', token)
      localStorage.setItem('token', JSON.stringify(token))
    } catch (error) {
      localStorage.clear('token')
      if (error.response.status === 400 && error.response.data.error_description === 'Invalid credentials given.') {
        throw new Error('Usuario o contraseña incorrectas.')
      }
      throw new Error('No se puede iniciar sesión, intente nuevamente en unos minutos.')
    }
  },
  logout: ({ commit }) => {
    commit('SET_TOKEN', null)
    localStorage.clear('token')
  },

}
