import axios from 'axios'
import store from '../../../store'
import router from '../../../router'
import app from '../../../main'

/* Hace que cada vez que se reciva un estado de no autorizado se limpie el token y usuario y se rediriga al login */

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // capturar cuando no hay response o status o data
    if (error.response.status === 401 && error.response.data.detail === 'Invalid token header. No credentials provided.') {
      store.dispatch('logout')
      router.push({ name: 'login' })
      app.$toast.error('Tu sesión ya no es valida.')
    } else if (error.response.status === 404) {
      throw error
    } else if (error.response.status === 400 && typeof error.response.data.message !== 'undefined') {
      app.$toast.error(error.response.data.message)
    } else {
      app.$toast.error('No se pudo completar la operación, intentelo nuevamente en unos momentos.')
    }
    return Promise.reject(error)
  },
)
