import Vue from 'vue'
import axios from 'axios'
import './interceptors/token'
import './interceptors/errorCatching'
  Vue.prototype.$backendURL = process.env.VUE_APP_BASE_URL

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = `${Vue.prototype.$backendURL}/api/`
axios.defaults.responseType = 'json'
Vue.prototype.$http = axios
