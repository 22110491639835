import axios from 'axios'

export default {
  fetchDispositivos: async ({ commit }) => {
    commit('SET_APP_LOADING', false, { root: true })
    const dispositivosResponse = await axios.get('iot/dispositivos/')
    const dispositivos = dispositivosResponse.data
    commit('SET_DISPOSITIVOS', dispositivos)
    commit('SET_APP_LOADING', false, { root: true })
  },
  fetchSensores: async ({ commit }, codigo_dispositivo) => {
    commit('SET_APP_LOADING', false, { root: true })
    const sensoresResponse = await axios.get(`iot/dispositivos/${codigo_dispositivo}/sensores/`)
    const sensores = sensoresResponse.data
    commit('SET_SENSORES', sensores)
    commit('SET_APP_LOADING', false, { root: true })
  },
}
